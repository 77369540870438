import React from 'react';
import { IconProps } from './types';

export interface ArrowProps extends IconProps {
  flipped: boolean;
}

export const Arrow: React.FC<ArrowProps> = ({ size, flipped, className }) => {
  const arrowDirection = flipped ? { transform: 'rotate(180deg)' } : {};

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" style={arrowDirection}>
      <path
        className={className}
        fill-rule="evenodd"
        d="M14.758 8L14.051 8.707L17.086 11.743L5 11.743L5 12.743L17.086 12.743L14.051 15.778L14.758 16.485L19 12.243L14.758 8Z"
      />
    </svg>
  );
};
