import React from 'react';
import { IconProps } from './types';

export const Tick: React.FC<IconProps> = ({ size, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <path
        className={className}
        fill-rule="evenodd"
        d="M4 13.4L9.10236 19C11.7021 13.9881 14.8846 9.37743 20 5C16.1715 7.04307 11.9514 10.9227 8.34646 15.7234L4 13.4Z"
      />
    </svg>
  );
};
